
h1{
    text-align: center;
}
.contact-container{
    display: flex;
    justify-content: space-evenly;
    padding: 10px 10px 0px 10px;
}

.contact-form{
    display: flex;
    justify-content: center;
    transition: 0.4s ease-in-out;
    border-radius: 10px;
    padding: 0px 20px 40px 20px;
}

.input{
    width: 400px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    position: relative;
}

.input label{
    position: absolute;
    left: 7px;
    top: -24px;
    transition: 0.3s ease-in-out;
}

.input input{
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 18px;
    border: solid 1px rgb(11, 161, 11);
    outline: none;
    transition: 0.3s ease-in-out;
    font-family: raleway;

}
.input textarea{
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 18px;
    border: solid 1px rgb(11, 161, 11);
    outline: none;
    height: 160px;
    transition: 0.4s ease-in-out;
    font-family: raleway;

}

.input button{
    transition: 0.3s ease-in-out;
    width: 150px;
    height: 50px;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
    border-radius: 25px;
    margin: auto;
    background-color: white;
    border: none;
    background-color: green;
    color: white;
    font-family: raleway;
}

.input button:hover{
    background-color: rgb(7, 96, 7);
    color: white;
    box-shadow: 0px 0px 1px 2px rgba(135, 222, 151,0.4);
}

.address{
    margin: 40px 10px 10px 200px;
    line-height: 30px;
}
.address p{
    font-size: 16px;
    letter-spacing: 1.5px;
}
.address .phone{
    font-family: Readex Pro;
}

.input input:focus,
.input textarea:focus{
    box-shadow: 0px 0px 1px 1px rgba(11, 161, 11, 0.4) ;
}
.input input:focus~label,
.input textarea:focus~label{
    color: rgb(11, 161, 11);
    font-weight: bold;
    left: 7px;
    top: -24px;
}

.successmsg{
    color: darkgreen;
}

.contact-container .contact-form .loader {
    margin: auto;
    border: 3px dotted #f3f3f3;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

@keyframes spin {
    0% { 
        transform: rotate(0deg);
    }
    100% { 
        transform: rotate(360deg); 
    }
}



/* ----- media query stats ----- */

@media (min-width:1201px){
    .contact-container .address{
        width: 35%;
    }
    .contact-container .contact-form{
        width: 46%;
    }
    .contact-form form{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;       
    }
    .input.user-name{
        width: 100%;
    }
    .input.email,
    .input.subject{
        width: 48%;
    }
    .input.message{
        width: 72%;
        margin-left: 0px;
    }
    .input.submit-btn{
        width: -moz-fit-content;
        width: fit-content;
        margin-right: 0px;
    }
    .input.email{
        margin-left: 0px;
    }
    .input.subject{
        margin-right: 0px;
    }
    .input label{
       color:green;
    }
    .input button{
        width: 150px;
        height: 150px;
        border: solid 1px black;
        border-radius: 50%;
        font-size: 22px;
    }
    .input button:hover{
        background-color: rgb(3, 148, 3);
    }

    .contact-container .contact-form .loader {
        border-width: 5px;
        width: 60px;
        height: 60px;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 3s linear infinite;
    }
}

@media (min-width:993px) and (max-width:1200px){
    .address {
        margin: 40px 10px 10px 80px;
    }

    .contact-form {
        width: 43%;
    }

    .input {
        width: 370px;
    }
}

@media (min-width:769px) and (max-width:992px){
    .address {
        margin: 40px 10px 10px 20px;
    }

    .contact-form {
        width: 46%;
    }

    .input {
        width: 370px;
    }

    .address h3{
        font-size: 17px;
    }


}


@media (max-width:768px){
    .contact-container{
        flex-direction: column;

    }
    .address{
        margin-left: 20px;
        margin-bottom: 60px;
    }
    .input textarea{
        font-size: 15px;   
    }
}


@media (max-width:600px){
    .contact-container .contact-form .input{
        width: 300px;
    }
}
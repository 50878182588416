
.container .each-details-container{
    width: 100%;
    line-height: 30px;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
}
.container .each-details-container  h3{
    margin-bottom: 10px;
}
.container .project-links ul{
    list-style-type: none;
}
.container .project-links ul li a{
    text-decoration: none;
}
.container .key-features ul{
    margin-left: 20px;
}
.container .key-features li{
    margin-bottom: 40px;
}


.key-features-screenshots-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px
}
.key-feature-screenshot-title > .key-feature-screenshot {
    box-shadow: 0px 0px 3px 1px grey;
    height: 450px;
    cursor: pointer;
}
.key-features-screenshots-container .swiper-slide > .key-feature-screenshot-title{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 5px;
    width: 90%;
    transition: 1s linear;
    margin: auto;
    padding: 5px;
    padding-top: 25px;
}

.key-features-screenshots-container .swiper-slide > 
.key-feature-screenshot-title .key-feature-screenshot img{
    max-width: 100%;
    max-height: 100%;
}




/* media queries */
@media (width < 1200px){
    .key-feature-screenshot-title > .key-feature-screenshot {
        height: 330px
    }
}
@media (width < 992px){
    .key-feature-screenshot-title > .key-feature-screenshot {
        height: 280px;
    }
}
@media (width < 768px){
    .key-features-screenshots-container .swiper-slide > .key-feature-screenshot-title {
        width: 100%;
    }
    .key-features-screenshots-container .swiper-button-prev,
    .key-features-screenshots-container .swiper-button-next {
        top: 58%;
    }
    .key-features-screenshots-container .swiper-button-prev:after,
    .key-features-screenshots-container .swiper-button-next:after {
        font-size: 23px;
    }
    .key-feature-screenshot-title > .key-feature-screenshot {
        height: 130px;    
    }
}
/* ----- bio start ----- */

@keyframes home{
    0%{
        opacity: 0;
        transform: translateY(10%);
    }
    70%{
        opacity: 1;
    }
    100%{
        transform: translateY(0%);
    }
}
@keyframes services{
    0%{
        transform: translateY(100%);
    }
    100%{
        transform: translate(0%);
    }
}

.bio-container {
    animation: home 0.5s ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 170px;
    margin-bottom: 140px;
}




/* --------------- media query starts --------- */
@media (width < 768px){
    .bio-container{
        flex-direction: column-reverse;
        padding: 15px;
        margin-top: 120px;
    }
}

.bio-container .image-container {
    width: 30%;
    padding-top: 30%;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 35px;
    position: relative;
}

.bio-container .image-container .image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    transition: 0.9s ease-in-out;
}




/* media queries */
@media (width < 768px){
    .bio-container .image-container{
        width: 94%;
        padding-top: 94%;
        margin-right: 0;
        margin-top: 35px;
    }
}

@media (width >= 768px) and (width < 1024px){
    .bio-container .image-container {
        width: 36%;
        padding-top: 36%;
    }
}
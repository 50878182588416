
.bio-container .about-me .hello {
    font-size: 105px;
    font-weight: bolder;
    margin-left: -6px;
    margin-bottom: 25px;
}

.bio-container .about-me h3 {
    margin-bottom: 12px;
    font-size: 22px;
    letter-spacing: 0.2px;
}
.bio-container .about-me p {
    font-family: League Spartan;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1px;
    font-weight: 320;
}


.bio-container .about-me p .hands-on-skills{
    color: transparent;
    background-image:linear-gradient(
        to right,
        rgb(29, 117, 171),
        rgb(163, 163, 28),
        green,
        rgb(35, 154, 238),
        rgb(163, 163, 28)
         ) ;
    -webkit-background-clip: text;
}




/* media queries */
@media (width < 768px){
    .bio-container .about-me .hello {
        font-size: 90px;
    }
}

@media (min-width:768px) and (max-width:1024px){
    .bio-container .about-me .hello {
        font-size: 90px;
    }
}

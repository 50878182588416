
.bio-container .about-me .services {
    display: flex;
    justify-content: space-evenly;
    gap: 2%;
    margin-top: 30px;
    margin-left:-10px;
}

.about-me .services .service-link.my-cv {
    animation: services 0.2s linear;
    background-color: rgb(70, 148, 211);
}
.about-me .services .service-link.projects {
    animation: services 0.4s linear;
    background-color: rgb(209, 211, 84);
}
.about-me .services .service-link.contact-me {
    animation: services 0.7s linear;
    background-color: rgb(89, 173, 89);
}

.about-me .services .service-link ,
.about-me .services .service-link ,
.about-me .services .service-link {
    position: relative;
    border: solid 1px;
    width: 35%;
    padding-top: 32%;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;
    font-weight: 700;
    overflow: hidden;
}
.my-cv, .projects, .contact-me {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;

}
.about-me .services .service-link {
    color: black;
    text-decoration: none;
}

.about-me .services .service-link:hover > :first-child{
    background-color: white; 
}




/* media queries */
@media (width < 768px){
    .bio-container .about-me .services {
        display: flex;
        margin-top: 30px;
        margin-left: 0px;
    }
}
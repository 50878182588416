.image-preview-container > .image-container{
    width: 90%;
    height: 95vh;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes img-preview{
    0%{
        scale: 0.5;
    }
    100%{
        scale: 1;
    }
}
.image-preview-container > .image-container img{
    max-width: 100%;
    max-height: 100%;
    animation: img-preview 0.5s ease-in-out;
    box-shadow: 5px 5px 30px rgba(28, 28, 28, 0.6);
}